import { useState } from 'react';
import axios from 'axios';
import TagManager from 'react-gtm-module';

const useEstimate = (formState, responseState, setResponseState, setDataLoaded, hardware, updateHardware) => {
  const [submitting, setSubmitting] = useState(false);
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);
  const [satelliteImageUrl, setSatelliteImageUrl] = useState('');
  const [customerErrMesg, setCustomerErrMesg] = useState('');

  const loadingTexts = [
    "Calculating cosmic rays...",
    "Photon disco in progress...",
    "Watt's up Beep Boop...",
    "Solar beams activating...",
    "Loading solar vibes....",
  ];

  const getEstimate = async () => {
    setSubmitting(true);
    try {
      // Display loading text
      setInterval(() => {
        setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 1600);

      const body = {
        name: formState.name,
        email: formState.email,
        phone: formState.phone,
        address: formState.address,
        customerName: formState.name,
        zip: formState.zip,
        aveBill: formState.electricBill,
        trades: hardware.solutions
      };

      const response = await fetch(process.env.REACT_APP_API_URL + '/estimate', {
          method: 'POST',
          headers: {
          'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
      });

      const result = await response.json();

      console.log("Estimate result", result);
      console.log("Estimate size", result.estimate_size);

      // API call to get estimate data
      // const url = `${process.env.REACT_APP_API_URL}/estimate?zip=${formState.zip}&aveBill=${formState.electricBill}&address=${formState.address}&trades=${hardware.solutions}`;
      // const result = await axios.get(url);

      // Update response state with the result
      const updatedResponseState = { ...responseState, 
        estimateSize: (result.estimate_size * 1000),
        solarProductionMonthly: result.solarProductionMonthly
      };

      setResponseState(updatedResponseState);

      // Fetch satellite image
      const image_url = `${process.env.REACT_APP_API_URL}/service/getHomeSatelliteImage?address=${formState.address}`;
      const imageResponse = await axios.get(image_url, { responseType: 'blob' });
      const imageBlob = imageResponse.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      setSatelliteImageUrl(imageUrl);
      updateHardware('satelliteImage', imageUrl);
      updateHardware('designImage', imageUrl);

      // Push data to Tag Manager
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_success'
        }
      });

      setDataLoaded(true);
      return true;
    } catch (error) {
      console.error("Error encountered", error.message);
      setCustomerErrMesg(error.message);
      TagManager.dataLayer({
        gtmId: "GTM-MD88S8R",
        dataLayer: {
          event: 'locate_next_clicked_estimate_error'
        }
      });
      return false;
    } finally {
      setSubmitting(false);
    }
  };

  return { getEstimate, submitting, setSubmitting, satelliteImageUrl, loadingTextIndex, loadingTexts, customerErrMesg, setCustomerErrMesg };
};

export default useEstimate;
