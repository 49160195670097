import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import { 
    AddressUrl, 
    Arrow, 
    BackButton, 
    ButtonContainer, 
    QuoteInnerWrapper, 
    ErrorText, 
    NextButton, 
    QuoteGrid, 
    QuoteHeader, 
    QuoteInput, 
    QuoteInputTitle, 
    QuoteInputWrapper, 
    QuoteTextSubtitle, 
    QuoteTextWrapper, 
    RegistrationErrorWrapper,
} from './QuoteElements'
import { Oval } from 'react-loading-icons'
import { HomeImg, ImageInternalWrapper, ImageWrapper } from './Design';
import OTPInput from './OTPInput';
import ModelVideo from './ModelVideo';
import videoSrc from '../../images/lunex_model/static_direct.mp4';

const OTPWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 30px;
`

const Customer = ({
    addressClick,
    formState,
    updateFormControl,
    errorFormState,
    nextButton,
    dataLoaded,
    submitting,
    setSubmitting,
    customerErrMesg,
    batteryOnly,
    setBatteryOnly,
    backButton,
    showLoadingIcon,
    hardware,
    handleCreateCustomer,
    loadingTexts,
    loadingTextIndex
}) => {
    const [showOTP, setShowOTP] = useState(false);
    const [otp, setOTP] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    useEffect(() => {
        if (showOTP && 'OTPCredential' in window) {
            const abortController = new AbortController();
    
            // Set a timeout to abort after 60 seconds
            const timeout = setTimeout(() => abortController.abort(), 60000);
    
            navigator.credentials
                .get({
                    otp: { transport: ['sms'] },
                    signal: abortController.signal,
                })
                .then((otpCredential) => {
                    if (otpCredential && otpCredential.code) {
                        console.log('OTP received:', otpCredential.code); // Log OTP
                        setOTP(otpCredential.code); // Auto-fill OTP
                    } else {
                        console.error('No OTP received.');
                    }
                    clearTimeout(timeout);
                })
                .catch((error) => {
                    if (error.name !== 'AbortError') {
                        console.error('Error fetching OTP:', error);
                    }
                });
    
            return () => {
                abortController.abort();
                clearTimeout(timeout);
            };
        }
    }, [showOTP]);

    // Function to handle form's Next button click
    const handleNextClick = async () => {
        const createSuccess = await handleCreateCustomer();
        if(createSuccess){
            setShowOTP(true);
        }
    };

    // Function to handle OTP Back button click
    const handleBackClick = () => {
        setShowOTP(false); // Go back to form view
        setOTP(''); // Clear OTP field
        setErrorMessage(''); // Clear error messages
    };

    // Function to handle OTP submission
    const handleOTPSubmit = async () => {
        try {
            setSubmitting(true);
            const response = await fetch(process.env.REACT_APP_API_URL + '/estimate/verifyOtp', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    address: formState.address, 
                    oneTimePassword: otp
                }), // Send OTP to backend for verification
            });

            if (response.ok) {
                const data = await response.json();
                if (data.verified) {
                    nextButton(); // Trigger the original nextButton function if OTP verified
                } else {
                    setErrorMessage('Invalid OTP. Please try again.');
                }
            } else {
                setErrorMessage('Failed to verify OTP. Please try again.');
            }
        } catch (error) {
            setErrorMessage('An error occurred. Please try again.');
            console.error("OTP Verification Error:", error);
        }
        setSubmitting(false);
    };

    useEffect(() => {
        if (otp.length === 6) {
            handleOTPSubmit(); // Automatically submit once OTP is complete
        }
    }, [otp]);

  return (
    <QuoteGrid>
        <ModelVideo videoSrc={videoSrc} playbackSpeed={0.5}/> 
        <QuoteInnerWrapper>
            {!showOTP &&
                <BackButton onClick={backButton}>
                    <Arrow>&lt;</Arrow>
                    Back
                </BackButton>
            }
            
            {/* <QuoteHeader>See your Solar Difference with</QuoteHeader> */}
            {/* <QuoteHeader>
                <LunexHeaderLogo src={logo} alt={'Lunex Logo Image'} customer={true}/>
            </QuoteHeader> */}
            {/* <ImageWrapper>
                <ImageInternalWrapper>
                    {hardware.designImage && <HomeImg src={hardware.designImage} alt="Satellite view of the home" />}
                </ImageInternalWrapper>
                <AddressUrl onClick={addressClick} disabled>{formState.address}</AddressUrl>
            </ImageWrapper>
            <br /> */}
            {!showOTP ? (
                <>
                <QuoteHeader>Tell Us About Yourself</QuoteHeader>
                <QuoteInputWrapper>
                    <QuoteInputTitle htmlFor='name'>Name</QuoteInputTitle>
                    <QuoteInput type='text' id='name' value={formState.name} onChange={updateFormControl} required/>
                </QuoteInputWrapper>
                <br />
                <QuoteInputWrapper>
                    <QuoteInputTitle htmlFor='email'>Email</QuoteInputTitle>
                    <QuoteInput type='text' id='email' value={formState.email} onChange={updateFormControl} required/>
                </QuoteInputWrapper>
                <br />
                <QuoteInputWrapper>
                    <QuoteInputTitle htmlFor='phone'>Phone</QuoteInputTitle>
                    <QuoteInput type='text' id='phone' value={formState.phone} onChange={updateFormControl} required/>
                </QuoteInputWrapper>
                <br />
                <QuoteTextWrapper>
                    <QuoteTextSubtitle>We will not spam you or share your information with anyone</QuoteTextSubtitle>
                </QuoteTextWrapper>
                <br />
                {/* <ButtonContainer> */}
                {!submitting &&
                    <ButtonContainer>
                        <NextButton onClick={handleNextClick} disabled={!formState.name || !errorFormState.hasEmailAndIsValid || !errorFormState.hasPhoneAndIsValid}>GET INSTANT PRICING</NextButton>
                    </ButtonContainer>
                }
                {submitting &&
                    <>
                        <ButtonContainer>
                            <NextButton disabled>
                                <Oval height='1.5rem'/>
                            </NextButton>
                        </ButtonContainer>
                        <RegistrationErrorWrapper>
                            {loadingTexts[loadingTextIndex]}
                        </RegistrationErrorWrapper>
        
                    </>                 
                }
                {customerErrMesg &&
                    <RegistrationErrorWrapper>
                        <ErrorText>{customerErrMesg}</ErrorText>
                    </RegistrationErrorWrapper>
                }
            </>
            ) : (
                <OTPWrapper>
                    <BackButton onClick={handleBackClick}>
                        <Arrow>&lt;</Arrow> Back
                    </BackButton>
                    <QuoteHeader>Enter the one-time password sent to your phone</QuoteHeader>
                    <QuoteInputWrapper>
                        <OTPInput length={6} setOTP={setOTP} />
                        {/* <QuoteInput type='text' id='otp' value={otp} onChange={(e) => setOTP(e.target.value)} placeholder="Enter OTP" required /> */}
                    </QuoteInputWrapper>
                    <ButtonContainer>
                        {!submitting &&
                            <NextButton onClick={handleOTPSubmit} disabled={otp.length !== 6}>
                                Verify
                            </NextButton>
                        }
                        {submitting &&
                            <NextButton loading={showLoadingIcon} disabled>
                                <Oval height='1.5rem'/>
                            </NextButton>                      
                        }
                    </ButtonContainer>
                    {errorMessage && (
                        <RegistrationErrorWrapper>
                            <ErrorText>{errorMessage}</ErrorText>
                        </RegistrationErrorWrapper>
                    )}
                </OTPWrapper>
            )}

        </QuoteInnerWrapper>
    </QuoteGrid>
  )
}

export default Customer