import React, { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
  50% {
    opacity: 0;
  }
`;

const BlinkingCursor = styled.span`
  font-size: 24px;
  font-family: monospace;
  animation: ${blink} 1s step-end infinite;
`;

const LoadingJokes = ({ delayBeforeTyping = 4000 }) => {
  const [typedText, setTypedText] = useState("");
  const [step, setStep] = useState(0);
  const [queue, setQueue] = useState([]);
  const [jokesQueue, setJokesQueue] = useState([]);
  const typingInProgress = useRef(false);
  const hasFetchedJokes = useRef(false); 

  const charIndexRef = useRef(0);

  const initialMessages = [
    "It takes humans an average of 42.5 minutes to create this design.",
    "Lucky for you, this will only take 90 seconds.",
    "While you wait, let AI entertain you with some jokes.",
  ];

  const defaultJokes = [
    { question: "Why does AI think it's smarter than humans?", answer: "Because it doesn't make typos!" },
    { question: "Why don't robots get tired?", answer: "They have unlimited battery life!" },
  ];

  const typingSpeed = 60;

  // Initialize queue with initial messages
  useEffect(() => {
    setQueue(initialMessages);
  }, []);

  // Fetch jokes and prepare them for later addition
  useEffect(() => {
    const fetchJokes = async () => {
      // if (hasFetchedJokes.current) return; // Prevent multiple fetches
      // hasFetchedJokes.current = true;

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/chat`);
        const data = await response.json();
        const jokes = data?.jokes || [];
        // Format the jokes into a question/answer array.
        const formattedJokes = jokes.flatMap((joke) => [joke.question, joke.answer]);
        // Append the jokes to the queue immediately.
        setQueue((prevQueue) => [...prevQueue, ...formattedJokes]);
      } catch (error) {
        console.error("Error fetching jokes:", error);
      }
    };
  
    fetchJokes();
  }, []);
  

  // Append jokes to the queue only after initial messages are typed
  useEffect(() => {
    if (step >= initialMessages.length && jokesQueue.length > 0) {
      // console.log("appending jokes");
      setQueue((prevQueue) => [...prevQueue, ...jokesQueue]);
      setJokesQueue([]); // Clear jokesQueue to avoid re-adding
    }
  }, [step, jokesQueue, initialMessages.length]);

  // Handle typing logic
  useEffect(() => {
    // console.log("step", step);
    // console.log("queue length", queue.length);
    // console.log("jokesQueue length", jokesQueue.length);

    if (typingInProgress.current || step >= queue.length) return;

    typingInProgress.current = true;

    const currentMessage = queue[step];
    setTypedText(""); // Reset for new message
    charIndexRef.current = 0;
    let tempText = "";

    const typeTimer = setInterval(() => {
      if (charIndexRef.current < currentMessage.length) {
        tempText += currentMessage[charIndexRef.current];
        setTypedText(tempText);
        charIndexRef.current++;
      } else {
        clearInterval(typeTimer); // Stop typing when the message is done

        setTimeout(() => {
          setStep((prevStep) => prevStep + 1);
          typingInProgress.current = false;
        }, delayBeforeTyping);
      }
    }, typingSpeed);

    return () => clearInterval(typeTimer); // Cleanup
  }, [step, queue, delayBeforeTyping]);

  return (
    <>
      {typedText}
      {step < queue.length && <BlinkingCursor>|</BlinkingCursor>}
    </>
  );
};

export default LoadingJokes;
